import * as React from "react";
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

const StyledNavigation = styled.div`

ul {
    list-style: none;
    padding: 0;

    display: flex;

    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    li {
        margin: 6px;

        padding: 8px 12px;

        font-size: 1.2rem;

        background-color: #e9e9ea;
        border-radius: 20px;

        transition: 300ms;

        :hover {
            background-color: #d9d9da;
        }

        a {
            color: #333;
            text-decoration: none;
        }

        a[aria-current="page"] {
            text-decoration: underline;
        }
    }

    @media (prefers-color-scheme: dark) {
        li {
            background-color: var(--background-muted);

            :hover {
                background-color: #2b2b2d;
            }

            a {
                color: var(--foreground-muted);
            }
        }
    }
}
`;

const Navigation = () => {
    return (
        <StaticQuery
        query={graphql`
        query {
            allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
              edges {
                node {
                  id
                  frontmatter {
                    title
                    date
                    slug
                  }
                }
              }
            }
        }
        `}
        render={(data) => {
            const { edges } = data.allMarkdownRemark;

            return (
                <StyledNavigation>
                    <nav>
                        <ul>
                            {edges.map(edge => {
                                return (
                                    <li key={edge.node.id}>
                                        <Link to={`/` + edge.node.frontmatter.slug}>
                                            {edge.node.frontmatter.title}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </StyledNavigation>
            )}
        }
      />
        
    );
};

export default Navigation;