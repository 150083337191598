import * as React from "react"

import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import SEO from "./seo";

import "modern-normalize"
import Header from './header';
import Navigation from './navigation';

const GlobalStyle = createGlobalStyle`
:root {
  --foreground-muted: #333;
  --background-muted: #e9e9ea;
}

html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.layout-root {
  padding: 14px;
}

.text-content {
  max-width: 720px;
  line-height: 1.5;

  hr {
    height: 1px;
    border: 1px solid #333;
    opacity: 0.2;
  }

  #last-update {
    display: flex;

    :before {
      content: "Letzte Änderung";
      display: block;
      font-size: 0.8rem;
      text-transform: uppercase;

      color: var(--foreground-muted);
      background-color: var(--background-muted);

      padding: 3px 6px;

      border-radius: 12px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #ffffff;
  }

  :root {
    --foreground-muted: #fff;
    --background-muted: #3b3b3d;
  }

  a {
    color: #ddd;
  }

  a:visited {
    color: #ccc;
  }
}
`;

const MainLayout: React.FC = ({ children }) => (
  <div className="layout-root">
    <Helmet>
      <script defer data-domain="doubletextwithlove.de" src="https://metrics.timweiss.net/js/plausible.js"></script>
    </Helmet>

    <SEO />
    <GlobalStyle />

    <Header />
    <Navigation />

    <div className="layout-main">{children}</div>
  </div>
);

export default MainLayout;