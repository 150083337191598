import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledHeader = styled.div`
    transition: 300ms;

    a {
        display: flex;
        padding-left: 0;
        justify-content: flex-end;

        @media (min-width: 601px) {
            max-width: 720px;
        }
    
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    :hover {
        opacity: 0.76;
    }
`;

const Header = () => {
    return (
        <StyledHeader>
            <Link to="/" title="Zur Startseite">
                <StaticImage src="../images/logo.svg" alt="double text with love" placeholder='none' layout='fixed' width={150} quality={100} />
            </Link>
        </StyledHeader>
    );
}

export default Header;