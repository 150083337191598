import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import openGraphDefault from '../images/open-graph-default.jpg';
import faviconSvg from '../images/favicon.svg';
import faviconPng from '../images/favicon.png';

function SEO({ title, description, meta, image }: { title?: string, description?: string, meta?: any, image?: string }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );
  const { pathname } = useLocation();

  const metaDescription = description || site.siteMetadata.description
  const siteTitle = title ? title + ` - ${site.siteMetadata.title}` : site.siteMetadata.title

  const allMeta = [
    {
        name: `description`,
        content: metaDescription,
    },
    {
        name: `twitter:card`,
        content: `summary_large_image`,
    },
    {
        name: `twitter:title`,
        content: siteTitle,
    },
    {
        name: `twitter:description`,
        content: metaDescription,
    }
  ].concat(meta ? meta : []);


  return (
    <Helmet>
        <html lang="de" />
        <title>{siteTitle}</title>
        {allMeta.map((meta) => (
            <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}

        <meta name="og:title" content={siteTitle} />
        <meta name="og:description" content={metaDescription} />
        <meta name="og:type" content="website" />
        <meta name="org:url" content={`${site.siteMetadata.siteUrl}${pathname}`} />
        <meta name="og:image" content={`${site.siteMetadata.siteUrl}${ image || openGraphDefault }`} />
        <meta name="twitter:image" content={`${site.siteMetadata.siteUrl}${ image || openGraphDefault }`} />

        <link rel="icon" type="image/svg+xml" href={faviconSvg} sizes="any" />
        <link rel="icon" type="image/png" href={faviconPng} />
    </Helmet>
  )
}

export default SEO;